import React from 'react'
import {useSkilQuery} from '../../Utilities/QueryClient'
import GoalCard from './GoalCard'
import LoadingComponent from '../../Components/LoadingComponent'

const NewUserCoursesGoal24 = () => {
    const {data: newUserCourses} = useSkilQuery<'getNewUserCoursesPerYear'>(`/api/user_courses/newUserCoursesPerYear`, {year: 2024})
    console.log('newUserCourses', newUserCourses)
    if (!newUserCourses) {
        return <LoadingComponent />
    }

    return (
        <GoalCard
            title='Kursdrift'
            subheader='Viser tall for kliniske emnekurs og e-læringskurs.'
            // @ts-expect-error
            description={`${newUserCourses?.signups} av 750 påmeldte hittil i 2024`}
            // @ts-expect-error
            percentage={Math.ceil((newUserCourses?.signups * 100) / 750)}
            // @ts-expect-error
            secondDescription={`${newUserCourses?.signupsQualityPackage} kliniske emnekurs`}
            // @ts-expect-error
            thirdDescription={`${newUserCourses?.payableSignupsQualityPackage} fakturerbare kliniske emnekurs`}
            // @ts-expect-error
            fourthDescription={`${newUserCourses?.signupsElearningCourses} e-læringskurs`}
            // @ts-expect-error
            fifthDescription={`${newUserCourses?.payableSignupsElearningCourses} fakturerbare e-læringskurs`}
            // @ts-expect-error
            sixthDescription={`${newUserCourses?.payableSignups} totale fakturerbare påmeldinger`}
            // @ts-expect-error
            seventhDescription={`${newUserCourses?.invited} inviterte`}
        />
    )
}

export default NewUserCoursesGoal24
